<template>
  <div>
    <AppHeader />
    <main>
      <section class="user-reviews-page">
        <h1>User Reviews and Testimonials</h1>
        <div class="reviews-container">
          <div class="review" v-for="review in visibleReviews" :key="review.id">
            <strong class="review-author">{{ review.author }}:</strong>
            <p>{{ review.text }}</p>
            <img v-if="review.image" :src="require(`@/assets/${review.image}`)" alt="Review Image" class="review-image" />
          </div>
        </div>
        <div v-if="visibleReviews.length < reviews.length" @click="loadMoreReviews" class="load-more-button">Load More &gt;&gt;&gt;</div>
        <DownloadButton downloadLink="https://example.com/download" />
      </section>
    </main>
    <AppFooter />
    <DownloadModal />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import DownloadButton from '@/components/DownloadButton.vue'
import DownloadModal from '@/components/DownloadModal.vue'

export default {
  name: 'UserReviewsPage',
  components: {
    AppHeader,
    AppFooter,
    DownloadButton,
    DownloadModal
  },
  data() {
    return {
      reviews: [
        { id: 1, text: 'Joguei nesta plataforma por alguns meses e já ganhei o equivalente ao meu salário anual! Eu costumava ser apenas um trabalhador comum, mas agora posso desfrutar de uma vida melhor, graças a esta plataforma que me deu essa oportunidade. Todos os dias, estou ansioso para fazer login na plataforma e ver meu saldo aumentar constantemente. É uma sensação indescritível de satisfação.', author: 'João Silva', image: 'review1.jpg' },
        { id: 2, text: 'Há uma grande variedade de jogos e muitas promoções e recompensas. É simplesmente incrível! Sempre há novas surpresas. A variedade de jogos na plataforma é impressionante, com novos eventos e ofertas todos os dias. Nunca fico entediado!', author: 'Rodrigo' },
        { id: 3, text: 'Eu costumava viver na pobreza, lutando diariamente para pagar as despesas básicas. Mas através desta plataforma, minha vida mudou drasticamente. Agora eu tenho minha própria casa e carro, além de economias suficientes para enfrentar incertezas futuras. Esta plataforma realmente mudou minha vida!', author: 'Maria Oliveira', image: 'review2.jpg' },
        { id: 4, text: 'Como mãe solteira, sempre procurei uma fonte extra de renda. Através da recomendação de um amigo, juntei-me a esta plataforma e rapidamente vi os resultados. Agora, não só posso sustentar a mim e aos meus filhos, mas também proporcionar-lhes uma melhor educação e condições de vida. Sou eternamente grata a esta plataforma.', author: 'Gabriel Costa', image: 'review3.jpg' },
        { id: 5, text: 'A variedade de jogos na plataforma é impressionante, com novos eventos e ofertas todos os dias. Nunca fico entediado! Seja um jogo clássico ou novo, aqui tem de tudo. A plataforma organiza frequentemente diversos eventos, o que me deixa sempre empolgado.', author: 'Marcelo' },
        { id: 6, text: 'Trabalhei em um emprego de baixa renda por muitos anos, nunca pensei que um dia poderia viver uma vida próspera. Desde que me juntei a esta plataforma, minha renda aumentou significativamente, não me preocupo mais com dinheiro. Eu até tenho tempo para perseguir meus hobbies, a qualidade de vida melhorou muito.', author: 'Ana Pereira', image: 'review4.jpg' },
        { id: 7, text: 'No início, eu era cético em relação a plataformas de jogos de azar online, mas a história de sucesso de um amigo me fez tentar. Agora, eu também sou parte de uma história de sucesso. Através desta plataforma, não só ganhei uma renda substancial, mas também fiz muitos amigos com interesses semelhantes.', author: 'Pedro Souza', image: 'review5.jpg' },
        { id: 8, text: 'A segurança da plataforma me deixa muito tranquila, cada transação é transparente e os fundos são creditados rapidamente. Como alguém que trabalha no setor financeiro há muitos anos, valorizo muito os mecanismos de segurança e o sistema de transações da plataforma. Cada operação aqui me deixa tranquila e confiante.', author: 'Juliana Rocha' },
        { id: 9, text: 'Usei várias plataformas de jogos de azar online, mas esta tem a melhor reputação. A justiça dos jogos e a segurança dos fundos são impecáveis. Cada vez que tenho um problema, o atendimento ao cliente resolve rapidamente. Esse tipo de serviço me deixa muito tranquilo.', author: 'Lucas Fernandes', image: 'review6.jpg' },
        { id: 10, text: 'A plataforma tem uma reputação muito alta, recomendada por amigos, e agora estou recomendando a todos. A segurança dos fundos e a justiça dos jogos são impecáveis. Continuarei jogando aqui e recomendando a mais amigos.', author: 'Beatriz Almeida', image: 'review7.jpg' },
        { id: 11, text: 'Como jogador experiente, tenho altas expectativas para as plataformas. Esta plataforma atende às minhas expectativas em termos de segurança, reputação e experiência do usuário. Cada vez que faço login, sinto o profissionalismo e o cuidado.', author: 'Mateus Santos' },
        { id: 12, text: 'Jogando nesta plataforma por alguns meses, sinto a segurança e a confiabilidade. Cada saque é rápido e nunca tive problemas. Tenho muita confiança nesta plataforma e continuarei apoiando-a.', author: 'Camila Martins', image: 'review9.jpg' },
        { id: 13, text: 'O design da interface da plataforma é muito fluido, a operação é simples e conveniente. Cada jogo é agradável, sem qualquer travamento. A velocidade de carregamento e a fluidez das operações são satisfatórias. Recomendo fortemente!', author: 'Rafael Lima', image: 'review10.jpg' },
        { id: 14, text: 'De todas as plataformas que joguei, esta tem a melhor experiência do usuário. Os jogos carregam rapidamente, a operação é suave, é incrível! Cada vez que faço login, sinto o profissionalismo e o cuidado.', author: 'Fernanda Ribeiro' },
        { id: 15, text: 'Como amante de jogos online, valorizo muito a experiência operacional. Esta plataforma não só tem uma interface bonita, mas também é muito fluida. Cada vez que jogo, fico muito feliz. Espero que a plataforma continue oferecendo essa experiência de alta qualidade.', author: 'Gustavo Araújo' },
        { id: 16, text: 'A interface operacional da plataforma é muito amigável, até mesmo os novatos conseguem aprender rapidamente. Cada jogo é fluido, sem qualquer atraso. Estou muito satisfeita com essa experiência do usuário e continuarei apoiando esta plataforma.', author: 'Mariana Mendes', image: 'review13.jpg' },
        { id: 17, text: 'A interface do usuário da plataforma é muito humanizada, é muito conveniente de operar. Cada jogo é fluido e nunca tive problemas. Essa experiência de alta qualidade me deixa muito satisfeito.', author: 'Felipe Correia' },
        { id: 18, text: 'O atendimento ao cliente é excelente, cada problema é resolvido rapidamente. Sou muito grata à equipe de atendimento ao cliente desta plataforma.', author: 'Amanda Cardoso' },
        { id: 19, text: 'Tive um problema de saque uma vez, e a atendente foi muito paciente em me ajudar a resolver. O serviço foi excelente! Esse atendimento me faz confiar ainda mais na plataforma.', author: 'Thiago Nascimento', image: 'review14.jpg' },
        { id: 20, text: 'O atendimento ao cliente da plataforma é realmente de primeira linha, cada problema é resolvido rapidamente. Seja um problema de fundos ou de jogo, o atendimento ao cliente sempre responde prontamente e resolve meus problemas.', author: 'Larissa Carvalho' },
        { id: 21, text: 'A variedade de jogos na plataforma é impressionante, com novos eventos e ofertas todos os dias. Nunca fico entediado! Todos os dias encontro diferentes jogos para jogar e há muitas recompensas para ganhar. É incrível!', author: 'Vinícius', image: 'review15.jpg' },
        { id: 22, text: 'Como amante de jogos, estou muito satisfeita com a variedade de jogos na plataforma. Há jogos clássicos e novos, e a plataforma organiza frequentemente diversos eventos, o que me deixa sempre empolgada.', author: 'Beatriz' },
        { id: 23, text: 'A variedade de jogos na plataforma é impressionante, com novos eventos e ofertas todos os dias. Nunca fico entediado! Estou sempre ansioso pelas novas promoções.', author: 'Patrícia', image: 'review16.jpg' },
      ],
      reviewsToShow: 10
    }
  },
  computed: {
    visibleReviews() {
      return this.reviews.slice(0, this.reviewsToShow);
    }
  },
  methods: {
    loadMoreReviews() {
      this.reviewsToShow += 5;
    }
  }
}
</script>

<style scoped>
.user-reviews-page {
  background-color: #e0ffe0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 2rem;
  margin: 2rem 0;
  width: 100%;
  max-width: 600px;
  text-align: center;
  color: #333;
}

.user-reviews-page h1 {
  color: #116a46;
}

.reviews-container {
  margin-top: 1rem;
}

.review {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  text-align: left;
}

.review-author {
  font-weight: bold;
  color: #116a46;
  display: block;
  margin-bottom: 0.5rem;
}

.review p {
  margin: 0.5rem 0;
}

.review-image {
  width: 100%;
  max-width: 400px;
  margin-top: 1rem;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.load-more-button {
  background-color: transparent;
  color: #116a46;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  text-decoration: underline;
}

.load-more-button:hover {
  background-color: transparent;
  color: #0e5a3b;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 100px);
  box-sizing: border-box;
  padding-bottom: 2rem;
  margin-top: 60px;
}

@media (max-width: 600px) {
  .user-reviews-page {
    padding: 1rem;
  }
}
</style>
