<template>
  <header @click="closeMenu">
    <div class="container">
      <div class="logo">
        <router-link to="/" @click="closeMenu">
          <img src="@/assets/logo.png" alt="Logo" class="logo-image" />
          <span>5ubet.com</span>
        </router-link>
      </div>
      <div class="menu-icon" @click.stop="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav :class="{ open: isMenuOpen }" @click.stop>
        <ul>
          <li><router-link to="/" @click="closeMenu">Início</router-link></li>
          <!--<li><router-link to="/platform-details" @click="closeMenu">Platform Details</router-link></li>-->
          <li><router-link to="/game-page" @click="closeMenu">Descrição do Jogo</router-link></li>
          <li><router-link to="/user-reviews" @click="closeMenu">Avaliações dos Usuários</router-link></li>
          <li><router-link to="/promotions" @click="closeMenu">Promoções da Plataforma</router-link></li>
          <li><a :href="downloadLink" class="download-button" @click="closeMenu">Baixe Agora</a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      isMenuOpen: false
    }
  },
  computed: {
    downloadLink() {
      return this.$config.downloadLink;
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    closeMenu() {
      this.isMenuOpen = false
    }
  }
}
</script>

<style scoped>
header {
  background-color: #116a46; /* 深绿色背景 */
  color: white; /* 白色文字 */
  padding: 1rem;
  text-align: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影 */
  box-sizing: border-box; /* 确保padding在内部，不会增加元素宽度 */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box; /* 确保padding在内部，不会增加元素宽度 */
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 40px;
  margin-right: 10px;
}

.logo a {
  color: white; /* 白色文字 */
  font-size: 1.5rem;
  text-decoration: none;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 30px;
  height: 21px;
  justify-content: space-between;
}

.menu-icon span {
  display: block;
  height: 3px;
  background: white; /* 白色文字 */
  border-radius: 3px;
  transition: 0.3s;
}

nav {
  display: none;
  position: absolute;
  top: 60px; /* 确保导航菜单位置固定 */
  left: 0;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影 */
  background-color: #116a46; /* 背景颜色 */
  z-index: 999; /* 确保菜单在前 */
}

nav.open {
  display: block;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

nav ul li {
  margin: 1rem 0;
}

nav ul li a {
  color: white; /* 白色文字 */
  text-decoration: none;
  font-size: 1rem;
}

nav ul li a:hover {
  text-decoration: underline;
}

.download-button {
  background-color: #ffd700; /* 金色按钮 */
  color: #116a46; /* 深绿色文字 */
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-align: center;
}

.download-button:hover {
  background-color: #ffc107;
}

@media (min-width: 600px) {
  nav {
    display: block;
    position: static; /* 确保在桌面设备上位置正确 */
    width: auto;
    box-shadow: none;
    background-color: transparent;
  }

  nav ul {
    flex-direction: row;
  }

  nav ul li {
    margin: 0 1rem;
  }

  .menu-icon {
    display: none;
  }
}
</style>
