<template>
    <div v-if="visible" class="download-modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <p>Download our app for the best experience!</p>
        <a class="download-button" :href="downloadLink">Baixe Agora</a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DownloadModal',
    computed: {
    downloadLink() {
      return this.$config.downloadLink;
    }
  },
    data() {
      return {
        visible: true
      }
    },
    methods: {
      closeModal() {
        this.visible = false
      }
    }
  }
  </script>
  
  <style scoped>
  .download-modal {
    position: fixed;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.8); /* 半透明背景 */
    color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .download-button {
    display: inline-block;
    padding: 8px 16px; /* 调整按钮大小 */
    background-color: #28a745;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
    font-size: 1rem; /* 调整字体大小 */
    transition: background-color 0.3s ease;
    box-sizing: border-box;
    max-width: 160px; /* 限制按钮最大宽度 */
    width: 100%; /* 确保按钮宽度自适应内容 */
  }
  
  .download-button:hover {
    background-color: #218838;
  }
  
  @media (max-width: 600px) {
    .download-button {
      padding: 6px 12px; /* 调整小屏幕上的按钮大小 */
      font-size: 0.875rem; /* 调整小屏幕上的字体大小 */
    }
  }
  </style>
  