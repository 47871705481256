<template>
  <footer>
    <p>© 2024 5ubet.com. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>
footer {
  background-color: #116a46; /* 深绿色背景 */
  color: white; /* 白色文字 */
  padding: 1rem;
  text-align: center;
  width: 100%;
  box-sizing: border-box; /* 确保padding在内部，不会增加元素宽度 */
  position: fixed; /* 固定在页面底部 */
  bottom: 0;
  left: 0;
}
</style>
