<template>
  <a :href="downloadLink" class="download-button">
    <slot>Cadastre-se Já</slot>
  </a>
</template>

<script>
export default {
  name: 'DownloadButton',
  computed: {
    downloadLink() {
      return this.$config.downloadLink;
    }
  }
}
</script>
<style scoped>
  .download-button {
    display: inline-block;
    padding: 10px 20px; /* 保持按钮的内边距，使其大小适中 */
    background-color: #28a745;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
    font-size: 1rem; /* 调整字体大小 */
    transition: background-color 0.3s ease;
    box-sizing: border-box;
    max-width: 200px; /* 限制按钮最大宽度 */
    width: auto; /* 确保按钮宽度自适应内容 */
  }
  
  .download-button:hover {
    background-color: #218838;
  }
  
  @media (max-width: 600px) {
    .download-button {
      padding: 8px 16px; /* 调整小屏幕上的按钮大小 */
      font-size: 0.875rem; /* 调整小屏幕上的字体大小 */
      width: 100%; /* 确保按钮在小屏幕上占满宽度 */
    }
  }
  </style>