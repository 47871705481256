import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import './assets/global.css'  // 引入全局样式



const app = createApp(App);

if (window.config) {
  app.config.globalProperties.$config = window.config;
} else {
  console.error('Config not found');
}

app.use(store);
app.use(router);
app.mount('#app');


