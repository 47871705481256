<template>
  <div>
    <AppHeader />
    <main>
      <section class="game-page">
        <h1>Nossos Jogos</h1>
        <div class="game-container" v-for="game in games" :key="game.id">
          <h2 class="game-title">{{ game.name }}</h2>
          <p class="game-description">{{ game.description }}</p>
          <img :src="game.image" alt="Game Image" class="game-image" />
        </div>
        <div class="download-button-container">
          <DownloadButton />
        </div>
      </section>
    </main>
    <AppFooter />
    <DownloadModal />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import DownloadModal from '@/components/DownloadModal.vue'
import DownloadButton from '@/components/DownloadButton.vue'

export default {
  name: 'GamePage',
  components: {
    AppHeader,
    AppFooter,
    DownloadModal,
    DownloadButton
  },
  data() {
    return {
      games: [
        {
          id: 1,
          name: 'Slots',
          description: 'Nossos jogos de slots estão cheios de emoção e surpresas! De clássicas máquinas de frutas a modernos slots de vídeo, cada jogo possui gráficos incríveis e temas envolventes. Se você é um novato ou um jogador experiente, nossos slots oferecem diversão sem fim e a chance de ganhar grandes prêmios.',
          image: require('@/assets/sloats.jpg')
        },
        {
          id: 2,
          name: 'Pescaria',
          description: 'Experimente nossos jogos de pescaria realistas e aproveite a diversão da pesca virtual! Com gráficos avançados e uma experiência de pesca autêntica, capture os maiores peixes e ganhe grandes recompensas. Nossos jogos de pescaria não só relaxam, mas também proporcionam uma enorme sensação de conquista.',
          image: require('@/assets/pescaria.jpg')
        },
        {
          id: 3,
          name: 'Blockchain',
          description: 'Explore nossos jogos inovadores baseados em blockchain. Esses jogos não só oferecem uma jogabilidade emocionante, mas também garantem transações transparentes e seguras. Com o uso de criptomoedas, você pode jogar com confiança e aproveitar os muitos benefícios da tecnologia blockchain.',
          image: require('@/assets/blockchain.jpg')
        },
        {
          id: 4,
          name: 'Esporte',
          description: 'Aposte nos seus esportes favoritos com nossos jogos de apostas esportivas! Oferecemos uma ampla variedade de opções de apostas. Sinta a emoção e o entusiasmo de apostar nos seus times e atletas preferidos.',
          image: require('@/assets/esporte.jpg')
        },
        {
          id: 5,
          name: 'Mais Jogos',
          description: 'Além desses jogos incríveis, nossa plataforma oferece uma variedade ainda maior de jogos para você explorar. Desde jogos de cassino ao vivo até loterias e jogos de mesa, sempre há algo novo e empolgante esperando por você na 5ubet.com. Venha descobrir todos os nossos jogos e aproveite ao máximo sua experiência de jogo online!',
          image: require('@/assets/popular.jpg')
        }
      ]
    }
  }
}
</script>

<style scoped>
.game-page {
  background-color: #f0f0f0;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
}

.game-container {
  margin: 2rem 0;
  padding: 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.game-title {
  font-size: 1.5rem;
  color: #116a46;
}

.game-description {
  margin: 1rem 0;
  color: #333;
}

.game-image {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.download-button-container {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  min-height: calc(100vh - 200px); /* Adjust based on header and footer height */
}

@media (max-width: 600px) {
  .game-page {
    padding: 1rem;
  }

  .game-container {
    padding: 0.5rem;
  }
}
</style>
