<template>
  <div>
    <AppHeader />
    <main>
      <section class="platform-details">
        <h1>Sobre Nossa Plataforma</h1>
        <!-- 添加图片或视频 -->
        <div v-if="mediaLink" class="media-container">
          <template v-if="isVideo">
            <video ref="video" controls :poster="videoPoster" class="media-element" @click="loadAndPlayVideo">
              <source :src="mediaLink" type="video/mp4">
            </video>
          </template>
          <template v-else>
            <img :src="mediaLink" alt="Platform Media" class="media-element">
          </template>
        </div>
        <DownloadButton downloadLink="https://example.com/download" />
        <div class="container">
        <h3>Sobre Nós</h3>
        <p>Desde 2020, a 5ubet.com é uma plataforma líder em jogos de azar. Oferecemos um serviço seguro, justo e de alta qualidade para usuários em todo o mundo.</p>

        <div class="section">
            <h3>Nossa História</h3>
            <p>Fundada em 2020, a 5ubet.com rapidamente se tornou uma líder de mercado. Obtivemos várias certificações, garantindo um ambiente de jogo seguro e regulamentado.</p>
        </div>

        <div class="section">
            <h3>Confiabilidade e Segurança</h3>
            <p>Nossa plataforma é rigorosamente testada, protegendo informações e fundos dos usuários. Utilizamos tecnologia de criptografia avançada e auditamos nossos jogos regularmente para garantir justiça e transparência.</p>
        </div>

        <div class="section">
            <h3>Usuário em Primeiro Lugar</h3>
            <p>Oferecemos suporte ao cliente 24/7 para garantir que você possa obter ajuda a qualquer momento. Nossa equipe de suporte é experiente e pronta para resolver seus problemas.</p>
        </div>

        <div class="section">
            <h3>Diversidade de Jogos</h3>
            <p>Oferecemos uma ampla variedade de jogos, incluindo apostas esportivas, eSports, jogos de cassino, caça-níqueis e loterias, atualizados regularmente.</p>
        </div>

        <div class="section">
            <h3>Comunidade e Responsabilidade</h3>
            <p>Defendemos o jogo responsável e oferecemos ferramentas para ajudar os usuários a gerenciar suas atividades de jogo. Participamos de atividades comunitárias e apoiamos causas de caridade.</p>
        </div>

        <p>Junte-se à 5ubet.com e experimente o melhor serviço de jogos de azar online. Visite nosso site oficial em <a href="https://5ubet.com">5ubet.com</a> e comece sua jornada de jogo!</p>
    </div>

        

       <h1>Conformidade e Licenciamento</h1>
        <p>Operamos sob licenças e supervisão de múltiplos órgãos reguladores autoritativos, incluindo os principais reguladores de jogos de azar no Brasil e internacionalmente. Nossa plataforma está totalmente em conformidade com todas as leis e regulamentos relevantes, garantindo os direitos legais e a segurança dos fundos dos nossos usuários.</p>
        <!-- 这里添加了 SEAE 和 Loterias CAIXA 的徽标 -->
        <div class="ad-images">
          <img src="@/assets/seae-logo.png" alt="Logotipo do Órgão Regulador 1">
          <img src="@/assets/loterias-caixa-logo.png" alt="Logotipo do Órgão Regulador 2">
        </div>
          <!-- 这里添加了国际监管机构的徽标 -->
        <div>
        <h3>Reconhecimento Internacional</h3>
          <p>No setor global de jogos de azar, a 5ubet.com se destaca por seu desempenho excelente e alta transparência. Fomos certificados pelos principais órgãos reguladores em vários países e regiões, e também gozamos de uma alta reputação internacionalmente.
          </p>
          <div class="ad-images">
            <img src="@/assets/international-regulator-logo1.png" alt="Logotipo do Órgão Regulador Internacional">
            <img src="@/assets/international-regulator-logo2.png" alt="Logotipo do Órgão Regulador Internacional">
          </div>
        </div>
        <h3>Garantia de Segurança</h3>
        <p>Empregamos a mais recente tecnologia de criptografia e medidas de segurança para proteger as informações pessoais dos usuários e a segurança das transações.</p>
        <!-- 这里添加了安全措施图标 -->
        <div class="ad-images">
          <img src="@/assets/ssl-icon.png" alt="Criptografia SSL">
          <img src="@/assets/data-protection-icon.png" alt="Proteção de Dados">
        </div>

        <h3>Confiança dos Usuários</h3>
        <p>Na 5ubet.com, sua confiança é nossa maior prioridade. Estamos comprometidos em fornecer um ambiente de jogo seguro, justo e regulamentado, para que você possa desfrutar da diversão do jogo com tranquilidade.</p>
        <!-- 这里添加了数据保护图片 -->
        <div class="ad-images">
          <img src="@/assets/user-data1.png" alt="Avaliação do Usuário 1">
          <img src="@/assets/user-data2.png" alt="Avaliação do Usuário 2">
        </div>
        <div class="container">
        <h1>Confiança e Credibilidade</h1>
        <p>No mundo dos jogos de azar online de hoje, confiança e credibilidade são fundamentais. Como líder do setor, a 5ubet.com sempre coloca a confiança e a satisfação do usuário em primeiro lugar. Nossa plataforma está comprometida em fornecer um ambiente de jogo seguro, justo e transparente, permitindo que cada usuário desfrute da emoção do jogo com tranquilidade.</p>

        <p>Nossa credibilidade não se reflete apenas nos serviços de alta qualidade e na experiência do usuário que oferecemos, mas também em nossas rigorosas medidas de segurança e conformidade. A 5ubet.com utiliza a tecnologia de criptografia mais avançada para garantir que as informações pessoais e os dados de transações dos usuários estejam sempre seguros. Fazemos parceria com os principais provedores de serviços de pagamento do mundo para garantir a transparência e a segurança de cada transação.</p>

        <p>Além disso, a 5ubet.com recebeu várias certificações e prêmios internacionais. Essas conquistas não apenas demonstram nossa força, mas também aumentam a confiança dos usuários em nós. Nossa plataforma tem sido altamente elogiada e recomendada por usuários e especialistas do setor em todo o mundo.</p>

        <p>A satisfação do usuário é nossa busca constante. Oferecemos suporte ao cliente 24/7 para garantir que os usuários possam receber assistência oportuna e profissional a qualquer momento. Seja um problema de jogo, consulta de pagamento ou segurança da conta, nossa equipe de atendimento ao cliente está dedicada a atendê-lo.</p>

        <p>Na 5ubet.com, entendemos que conquistar a confiança do usuário é a chave para obter o reconhecimento do mercado. Continuaremos nos esforçando para melhorar a qualidade do serviço e a experiência do usuário, visando nos tornar sua plataforma de jogos de azar online mais confiável.</p>

        <p>Junte-se a nós e experimente um ambiente de jogo verdadeiramente seguro e confiável. 5ubet.com, sua melhor escolha!</p>
    </div>
        <div class="reviews-container">
          <DownloadButton downloadLink="https://example.com/download" />
        </div>
      </section>
    </main>
    <AppFooter />
    <DownloadModal />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import DownloadModal from '@/components/DownloadModal.vue'
import DownloadButton from '@/components/DownloadButton.vue'

export default {
  name: 'PlatformDetails',
  components: {
    AppHeader,
    AppFooter,
    DownloadModal,
    DownloadButton
  },
  data() {
    return {
      mediaLink: 'https://source.syuo5u.com/5ubetgames.mp4', // 远程视频链接或者本地链接，例如 require('@/assets/media.mp4')
      videoPoster: require('@/assets/banner.jpg') // 'http://vjs.zencdn.net/v/oceans.mp4'
    }
  },
  computed: {
    limitedReviews() {
      return this.reviews.slice(0, 4);
    },
    isVideo() {
      return this.mediaLink.endsWith('.mp4');
    }
  },
  methods: {
    loadAndPlayVideo() {
      const videoElement = this.$refs.video;
      if (videoElement) {
        videoElement.play();
      }
    }
  }
}
</script>

<style scoped>
.platform-details {
  background-color: #e0ffe0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 2rem;
  margin: 2rem 0;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.media-container {
  margin-top: 1rem;
}

.media-element {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
}

.ad-images {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.ad-images img {
  max-width: 150px;
  border-radius: 5px;
}

.reviews-container {
  margin-top: 1rem;
}

.review {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  text-align: left;
}

.review-author {
  font-weight: bold;
  color: #116a46;
  display: block;
  margin-bottom: 0.5rem;
}

.review p {
  margin: 0.5rem 0;
}

.review-image {
  width: 100%;
  max-width: 400px;
  margin-top: 1rem;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 100px);
  box-sizing: border-box;
  padding-bottom: 2rem;
  margin-top: 60px;
}

@media (max-width: 600px) {
  .platform-details {
    padding: 1rem;
  }
}
</style>
