import { createRouter, createWebHistory } from 'vue-router'
import PlatformDetails from '@/views/PlatformDetails.vue'
import GamePage from '@/views/GamePage.vue'
import UserReviewsPage from '@/views/UserReviewsPage.vue'
import PromotionsPage from '@/views/PromotionsPage.vue'

const routes = [
  {
    path: '/',
    name: 'PlatformDetails',
    component: PlatformDetails
  },
  {
    path: '/game-page',
    name: 'GamePage',
    component: GamePage
  },
  {
    path: '/user-reviews',
    name: 'UserReviews',
    component: UserReviewsPage
  },
  {
    path: '/promotions',
    name: 'Promotions',
    component: PromotionsPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
