<template>
  <div>
    <AppHeader />
    <main>
      <section class="promotions-page">
        <h1>Our Promotions</h1>
        <div class="promotion-container" v-for="promotion in promotions" :key="promotion.id">
          <h2 class="promotion-title">{{ promotion.title }}</h2>
          <p class="promotion-description" v-html="promotion.description"></p>
          <img :src="require(`@/assets/${promotion.image}`)" alt="Promotion Image" class="promotion-image" />
          <button @click="getOffer" class="get-offer-button">Participe Agora</button>
        </div>
      </section>
    </main>
    <AppFooter />
    <DownloadModal />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import DownloadModal from '@/components/DownloadModal.vue'

export default {
  name: 'PromotionsPage',
  components: {
    AppHeader,
    AppFooter,
    DownloadModal
  },
  data() {
    return {
      promotions: [
        {
          id: 1,
          title: 'bônus de primeiro depósito',
          description: `➡️O depósito mínimo é de R$ 10 e você ganhará R$ 5! Prêmios de até R$ 888<br>
                        😂Como resgatar<br>
                        1️⃣Cadastre-se;<br>
                        2️⃣Faça login;<br>
                        3️⃣Clique em “Depositar” e faça um primeiro depósito de no mínimo R$10;<br>
                        4️⃣Clique em “Promoção”;<br>
                        5️⃣Clique em “Bônus de Primeiro Depósito”;<br>
                        6️⃣Clique em "Coletar tudo".<br>
                        Detalhes do evento: Clique para entrar no "Centro de Atividades" para visualizar 💲quanto mais você depositar, mais bônus receberá!<br>
                        🎁Lembrete: Os clientes são aconselhados a baixar o APP e fazer login todos os dias para ter a chance de ganhar 💲bônus misteriosos (até R$ 88.888) e participar de mais bônus. Feliz jogo!🤝`,
          image: 'promotion1.jpg'
        },
        {
          id: 2,
          title: 'usuários agentes',
          description: `💯➡️➡️Sinceramente convidamos todos os jogadores a se tornarem "usuários agentes" 5ubet, terem uma "equipe de agentes" que pertence a você e ganharem "comissão ilimitada" + bônus "recomendar amigos" ~<br>
                        👑Modo Agente: Calculado com base na situação real de apostas dos membros de nível inferior. 💖Não há necessidade de se preocupar em reduzir os lucros pessoais devido ao desenvolvimento tardio ou recursos insuficientes. 💓Contanto que você acumule apostas válidas💥, você poderá desfrutar de maiores taxas de desconto para agentes. 🐾Este é definitivamente um modelo de agência justo e não prejudicará os membros que ingressarem mais tarde💖<br>
                        Cinco benefícios de se tornar um agente<br>
                        🚩R$ 20 por convite de novo associado;<br>
                        🚩Comissão diária de apostas 2%;<br>
                        🚩Comissão adicional diária de 5%;<br>
                        🚩Comissão semanal extra de 20%;<br>
                        🚩Comissão extra de 50% todo mês.<br>
                        🌟Resumo: Apostas efetivas geradas por membros de baixo nível jogando = taxa de desconto do agente⭐️`,
          image: 'promotion2.jpg'
        },
        {
          id: 3,
          title: 'Atividades do Fundo de Previdência',
          description: `5ubet.com mostra a todos os jogadores como obter 💵 - Recarga {50%} 💵 Bônus “Fornecer Fundo”<br>
                        📝Regras de atividade: A proporção de bônus do “fundo de previdência” é de 50%. 💸Por exemplo: Durante o “ciclo” da atividade, se o seu depósito atingir R$ 200,00, a recompensa do “fundo de previdência” é de R$ 100,00, {um mês é um ciclo} ❤️ 🙃<br>
                        💲Requisitos para receber o fundo de previdência: Você precisa apostar {30 vezes o valor da aposta} do valor do depósito para receber o fundo de previdência. 💲Quanto mais depósitos, maior será o fundo de previdência. Você pode recebê-lo após atingir o valor da aposta;<br>
                        ➡️Passos para reivindicar: Faça login em sua conta, clique em → Fundo de Previdência, clique em → Retirar ☑️`,
          image: 'promotion3.jpg'
        },
        {
          id: 4,
          title: 'Envelope vermelho para evento diário',
          description: `🔥Você só precisa se tornar um membro do 5Ubet.com e fazer login em sua conta de membro com o APP móvel todos os dias - você pode aproveitar a "chuva do envelope vermelho da sorte"😍<br>
                        💎Estrutura de bônus: 8 jogos por dia, com um único bônus de envelope vermelho de até R$888. Quanto mais você depositar ou apostar, maior será o valor do bônus e mais recompensas você receberá.<br>
                        📣O envelope vermelho aparecerá automaticamente no lobby da plataforma. Lembre-se de fazer login em 5Ubet.com para evitar perder o envelope vermelho🐝`,
          image: 'promotion4.jpg'
        },
        {
          id: 5,
          title: 'Faça check-in todos os dias',
          description: `👏O "Bônus Misterioso Diário" foi enviado para sua conta. Faça login em sua conta de membro para reivindicá-la. O bônus é válido por 1 dia.<br>
                        💰O bônus "Primeiro Depósito do Membro 2-3-7-15-30" foi enviado para sua conta. Basta fazer login na sua conta de membro. O bônus do 2º dia é válido por 1 dia; o bônus do 3º dia é válido por 3 dias; o bônus do 7º ao 15º dia é válido por 7 dias.O bônus de 30 dias é válido por 7 dias.<br>
                        💵Os bônus de apostas diárias são enviados automaticamente para sua conta e não há necessidade de reivindicá-los. Faça login em sua conta de membro para aceitar.<br>
                        📝Etapas para reivindicar: Clique em Eventos➡️Mais Recompensas➡️Reivindicar. Obrigado por participar e desejo o melhor a todos os jogadores. Feliz jogo!`,
          image: 'promotion5.jpg'
        }
      ]
    }
  },
  methods: {
    getOffer() {
      const getOfferLink = this.$config.getOfferLink;
      console.log('Offer link in getOffer:', getOfferLink);
      if (getOfferLink) {
        window.location.href = getOfferLink;
      } else {
        console.error('Get Offer link is not available');
      }
    }
  }
}
</script>

<style scoped>
.promotions-page {
  background-color: #ffebcd; /* 浅杏色背景 */
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
}

.promotion-container {
  margin: 2rem 0;
  padding: 1rem;
  background-color: #fff8dc; /* 柔和的黄色背景 */
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  text-align: left; /* 左对齐 */
}

.promotion-title {
  font-size: 2rem;
  color: #ff4500; /* 橙红色标题 */
}

.promotion-description {
  margin: 1rem 0;
  color: #ff6347; /* 番茄色描述 */
  text-align: left; /* 左对齐 */
}

.promotion-image {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  display: block;
  margin: 0 auto;
}

.get-offer-button {
  background-color: #ff4500;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1rem;
  display: block;
  margin: 1rem auto 0;
}

.get-offer-button:hover {
  background-color: #ff6347;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
}

@media (max-width: 600px) {
  .promotions-page {
    padding: 1rem;
  }

  .promotion-container {
    padding: 0.5rem;
  }
}
</style>
